body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*все скролл-бары*/
html * ::-webkit-scrollbar {
    background-color: inherit;
    width: 4px;
}
html * ::-webkit-scrollbar-button {
    display: none;
}
html * ::-webkit-scrollbar-track {
    background-color: inherit;
}
html * ::-webkit-scrollbar-thumb {
    width: 4px;
    background-color: #7e2aea;
    color: #7e2aea;
    border-radius: 5px;
}

.cnvs {
    width: 80px;
}

/*
::-webkit-scrollbar — это фон самого скроллбара.
::-webkit-scrollbar-button — кнопки направления на полосе прокрутки.
::-webkit-scrollbar-track — пустое пространство под индикатором прокрутки.
::-webkit-scrollbar-thumb — индикатор прокрутки, перетаскиваемый элемент.
*/


video {
    pointer-events: none;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
